import { useTranslation } from 'next-i18next';

import { RTL_LANGUAGES } from 'constants/rtl-languages';

type TTextDirection = 'rtl' | 'ltr';

type TUseTextDirection = () => TTextDirection;

export const useTextDirection: TUseTextDirection = () => {
  const { i18n } = useTranslation();

  return RTL_LANGUAGES.includes(i18n.language) ? 'rtl' : 'ltr';
};
